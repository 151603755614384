import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, InputNumber, Form, Select, Space, DatePicker, message } from 'antd';
import { MinusOutlined, PlusOutlined, CarOutlined, ExperimentOutlined, CloseOutlined, PauseOutlined} from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

import { Api, HtmlStrings } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';
import TextAreaCount from '../../../TextAreaCount';

const entities = new AllHtmlEntities();

function ComandesAcollidaMinutEdit(props){

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const id = props.item.id;
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [id_pare, setId_pare] = useState(props.item.id_pare);
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [tipus, setTipus] = useState(props.item.tipus ? props.item.tipus : 'mati');
  const [puntual_data, setPuntual_data] = useState(props.item.puntual_data ? props.item.puntual_data : moment.utc(new Date()));
  const [h_ini, setH_ini] = useState(props.item.h_ini > 0 ? props.item.h_ini : 16);
  const [m_ini, setM_ini] = useState(props.item.m_ini);
  const [h_fin, setH_fin] = useState(props.item.h_fin > 0 ? props.item.h_fin : 16);
  const [m_fin, setM_fin] = useState(props.item.m_fin);
  const [minuts_facturar, setMinuts_facturar]  = useState(props.item.minuts_facturar);
  const [preu, setPreu] = useState(props.item.preu);
  const [subtotal, setSubtotal] = useState(props.item.subtotal);
  const [descompte, setDescompte] = useState(props.item.descompte);
  const [total, setTotal] = useState(props.item.total);
  const [puntual_facturat, setPuntual_facturat] = useState(props.item.puntual_facturat === 1 ? true : false);
  const [id_classe, setId_classe] = useState(props.item.id_classe);
  const [id_curs_escolar, setId_curs_escolar] = useState(props.item.id_curs_escolar > 0 ? props.item.id_curs_escolar :  props.id_curs_escolar);

  const [familiesOptions, setFamiliesOptions] = useState([]);
  const [paresOptions, setParesOptions] = useState([]);
  const [alumnesOptions, setAlumnesOptions] = useState([]);



  const [isLoadingClasse, setIsLoadingClasse] = useState(false);

  const [llistaAlumnes, setLlistaAlumnes] = useState([]);
  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);

  const [errorId_familia, setErrorId_familia] = useState('');
  const [errorId_familiaText, setErrorId_familiaText] = useState('');
  const [errorId_alumne, setErrorId_alumne] = useState('');
  const [errorId_alumneText, setErrorId_alumneText] = useState('');
  const [errorId_pare, setErrorId_pare] = useState('');
  const [errorId_pareText, setErrorId_pareText] = useState('');
  const [errorH_ini, setErrorH_ini] = useState('');
  const [errorH_fin, setErrorH_fin] = useState('');

  const nou = (props.item.id===0);


  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);


  useEffect(() => {
    if(id_familia > 0){
      buscarDadesPares();
      buscarDadesFills();
    }
    buscarDadesFamilia();
  },[]);

  useEffect(() => {
    buscarDadesPares();
    buscarDadesFills();
  },[id_familia]);


  useEffect(() => {
    calcularSubtotal(preu, minuts_facturar);
  },[preu, minuts_facturar]);

  useEffect(() => {
    calcularTotal(subtotal, descompte);
  },[subtotal, descompte]);

  useEffect(() => {
    calcularMinuts();
  },[h_ini, h_fin, m_ini, m_fin]);

  useEffect(() => {
    buscarPreuAlumne();
  },[id_alumne]);




  const calcularMinuts = () =>{
    const minuts_inici = (h_ini*60) + m_ini;
    const minuts_fi = (h_fin*60) + m_fin;
    setMinuts_facturar(minuts_fi - minuts_inici);
  }

  const buscarDadesFamilia = async () => {
    const fam = await Api.get('/families');
    if(fam.result.success===1){
      const llista_fam = fam.data.families.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {item.id + ' - ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
              </Select.Option>
            );
      });
      setFamiliesOptions(llista_fam);
    }
  }
  const buscarDadesPares = async () => {
    if(id_familia > 0){
      const pares = await Api.get(`/pares/familia/${id_familia}`);
      if(pares.result.success===1){
        const llista_pares = pares.data.map((item) =>{
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setParesOptions(llista_pares);
      }
    }
  }
  const buscarDadesFills = async () => {
    if(id_familia > 0){
      const fills = await Api.get(`/alumnes/familia/${id_familia}`);
      if(fills.result.success===1){
        const llista_fills =fills.data.alumnes.map((item) =>{
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setAlumnesOptions(llista_fills);
      }
    }
  }
  const buscarPreuAlumne = async () => {
    if(id_alumne > 0){
      const p = await Api.get(`/acollidaminuts/preu/${id_alumne}/${id_curs_escolar}`);
      if(p.result.success===1){
        setPreu(p.data.preu);
      }
    }
  }

  const submitForm = async (valors) => {

    let correcte = true;

    if(id_alumne===0){
      setErrorId_alumne('error');
      setErrorId_alumneText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_pare===0){
      setErrorId_pare('error');
      setErrorId_pareText(props.t('generic.tria'));
      correcte = false;
    }
    if(tipus==='mati'){
      if(h_ini===0){
        setErrorH_ini('error');
        correcte = false;
      }
    }
    if(tipus==='tarda'){
      if(h_fin===0){
        setErrorH_fin('error');
        correcte = false;
      }
    }

    if(!correcte){
      return false;
    }

    setGuardant(true);

    let dades = {
      id_alumne,
      id_pare,
      tipus,
      puntual_data,
      h_ini,
      m_ini,
      h_fin,
      m_fin,
      id_curs_escolar,
      descompte
    }

    //console.log(dades);
    //console.log(JSON.stringify(dades));

    let method = nou?'POST':'PUT';
    let url_api = nou? '/acollidaminuts/comanda' : '/acollidaminuts/comanda/'+id;


    try{
      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        if(props.tancarModal){
          props.tancarModal();
        }
        if(props.callback){
          props.callback();
        }

      }else{
        setGuardant(false);
        console.error('Error: enviant notificacio: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Enviant Notificacio: ', error);
    }

  }



  const changePuntual_data = (date, dateString) => {
    if(date){
      //Trec l'hora
      setPuntual_data(date.format(moment.HTML5_FMT.DATE));
    }else{
      setPuntual_data(null);
    }
  }

  const triaFamilia = (value) =>{
    setId_familia(value);
    if(value>0){
      setErrorId_familia('');
      setErrorId_familiaText('');
      setId_pare(0);
      setId_alumne(0);
    }
  }

  const triaPare = (value) =>{
    setId_pare(value);
    if(value>0){
      setErrorId_pare('');
      setErrorId_pareText('');
    }
  }

  const triaAlumne = (value) =>{
    setId_alumne(value);
    if(value>0){
      setErrorId_alumne('');
      setErrorId_alumneText('');
    }
  }

  const calcularTotal = (sub, desc) =>{
    if(sub !== 0.0){
      if(desc!==0.0){
        const tot =  sub - (sub * desc / 100);
        setTotal(parseFloat(tot).toFixed(2));
      }else{
        setTotal(sub);
      }
    }else{
      setTotal(0);
    }
  }

  const calcularSubtotal = (_preu, _unitats) => {
    if(_preu !== 0.0 && _unitats!==0.0){
      setSubtotal(parseFloat(_preu * _unitats).toFixed(2));
    }else{
      setSubtotal(0);
    }
  }

  const onChangePreu = (_preu) =>{
    setPreu(_preu);
  }

  const onChangeDescompte = (desc) =>{
    setDescompte(desc);
  }


  const arreglarTxt4Search = text =>{
      // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  const dateFormat = 'DD/MM/YYYY';

  return(
    <>
      <Form onFinish={submitForm} layout="vertical" autoComplete="off">

      <Row justify="space-between" align="middle">
        <Col>
          <Form.Item label={props.t('generic.data')} required={true}>
            <DatePicker
              onChange={changePuntual_data}
              value={puntual_data ? moment(new Date(puntual_data), dateFormat) : ''}
              format={dateFormat}
              locale={localeDatePicker}
              picker="date"
              />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={props.t('acollida.tipus')} required={true} validateStatus={errorId_familia} help={errorId_familiaText} style={{marginBottom:0}}>
            <Select value={tipus} onChange={(value) => setTipus(value)} defaultActiveFirstOption={true}  style={{width:'100%'}}>
                <Select.Option key={'mati'} value={'mati'}>{props.t('acollida.mati')}</Select.Option>
                <Select.Option key={'tarda'} value={'tarda'}>{props.t('acollida.tarda')}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

        <Row justify="space-between" align="middle">
          <Col>
            <Form.Item label={props.t('generic.hini')} required={true} validateStatus={errorH_ini}>
                <InputNumber name="hora" onChange={setH_ini} value={h_ini} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
                <span>&nbsp;:&nbsp;</span>
                <InputNumber name="minut" onChange={setM_ini} value={m_ini} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
            </Form.Item>
            </Col>
            <Col>
            <Form.Item label={props.t('generic.hfin')} required={true} validateStatus={errorH_fin}>
                <InputNumber name="hora" onChange={setH_fin} value={h_fin} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
                <span>&nbsp;:&nbsp;</span>
                <InputNumber name="minut" onChange={setM_fin} value={m_fin} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
            </Form.Item>
          </Col>
      </Row>


      <Form.Item label={props.t('alumnes.familia')} required={true} validateStatus={errorId_familia} help={errorId_familiaText} style={{marginBottom:0}}>
        <Select
          defaultValue={id_familia===0 ? '' : id_familia} onChange={(value) => triaFamilia(value)}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
        >
          {familiesOptions}
        </Select>
      </Form.Item>

    {id_familia <=0 ? null :
      <>
        <Form.Item label={props.t('generic.responsable')} required={true} validateStatus={errorId_pare} help={errorId_pareText} style={{marginBottom:0}}>
          <Select
            value={id_pare===0 ? '' : id_pare} onChange={(value) => triaPare(value)}
            showSearch
            filterOption={(input, option) =>
              HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
            }
          >
            {paresOptions}
          </Select>
        </Form.Item>
        <Form.Item label={props.t('generic.alumne')} required={true} validateStatus={errorId_alumne} help={errorId_alumneText} style={{marginBottom:0}}>
          <Select
            value={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
            showSearch
            filterOption={(input, option) =>
              HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
            }
          >
            {alumnesOptions}
          </Select>
        </Form.Item>
      </>
    }

    <Row align="middle" justify="space-around">
      <Col>
        <Form.Item label={props.t('generic.preu')}>
            <InputNumber name="preu" onChange={onChangePreu} value={preu} disabled
            step={0.01}
            formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/€\s?|(,*)/g, '')}
            />
        </Form.Item>
      </Col>
      <Col style={{paddingTop: 8}}>
        <CloseOutlined />
      </Col>
      <Col>
        <Form.Item label={props.t('acollida.minuts')}>
            <InputNumber value={minuts_facturar} precision={0} min={0} disabled/>
        </Form.Item>
      </Col>
      <Col style={{paddingTop: 8}}>
        <PauseOutlined rotate={90}/>
      </Col>

      <Col>
        <Form.Item label={props.t('generic.subtotal')}>
            <InputNumber name="subtotal" value={subtotal} disabled
            step={1.0}
            formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/€\s?|(,*)/g, '')}
            />
        </Form.Item>
      </Col>
      <Col style={{paddingTop: 8}}>
        <MinusOutlined />
      </Col>
      <Col>
        <Form.Item label={props.t('generic.descompte')}>
            <InputNumber name="descompte" onChange={onChangeDescompte} value={descompte} disabled={puntual_facturat}
            min={-100} max={100} step={0.1}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
            />
        </Form.Item>
      </Col>
      <Col style={{paddingTop: 8}}>
        <PauseOutlined rotate={90}/>
      </Col>
      <Col>
        <Form.Item label={props.t('generic.total')}>
            <InputNumber name="total" value={total} disabled
            formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/€\s?|(,*)/g, '')}
            />
        </Form.Item>
      </Col>
    </Row>

        <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
          <Col>
            <Button onClick={()=>props.tancarModal()}>{props.t('generic.cancel')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default translate(ComandesAcollidaMinutEdit);
