import React, {useState, useEffect, useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table, Tabs, Input, Space, Button, Row, Col, Badge, Tooltip, Popconfirm, Modal, Select } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined, FolderOutlined,
         FolderFilled, FilePdfTwoTone, FileImageTwoTone, FileWordTwoTone,
         FileExcelTwoTone, FilePptTwoTone, FileTextTwoTone,
         EyeTwoTone, EyeInvisibleTwoTone, DownloadOutlined,
         LockFilled, UnlockFilled
       } from '@ant-design/icons';

import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
//import LlistaEditable from '../../../components/LlistaEditable';
import LlistaEditable from '../../../components/LlistaEditableModalNouEdit';
import Edit from '../../../components/Formularis/perfils/admin/Pares';
import AfegirDocument from '../../../components/Formularis/perfils/admin/DocumentsAfegirFitxer';
import AfegirCarpeta from '../../../components/Formularis/perfils/admin/DocumentsCarpeta';
import { Api, InitDades } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();

function Carpetes(props) {

  const [perfilUsuari ] = useContext(PerfilUsuariContext);
  const [carpeta_personal, setCarpeta_personal] = useState(props.carpeta_personal ? props.carpeta_personal : 0);
  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA

  const [llista, setLlista] = useState([]);
  const [modalAfegirVisible, setModalAfegirVisible] = useState(false);
  const [modalAfegirCarpetaVisible, setModalAfegirCarpetaVisible] = useState(false);
  const [carpetaActual, setCarpetaActual] = useState(InitDades.carpeta());
  const [fitxerActual, setFitxerActual] = useState(InitDades.fitxer());
  const [taulaIsLoading, setTaulaIsLoading] = useState(false);
  const [carpetaIsLoading, setCarpetaIsLoading] = useState(false);
  const [refrescar, setRefrescar] = useState(0);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  //const [optAlumnes, setOptAlumnes] = useState([]);
  const [optUsuaris, setOptUsuaris] = useState([]);
  const [optClasses, setOptClasses] = useState([]);
  const [optCursos, setOptCursos] = useState([]);
  const [optCicles, setOptCicles] = useState([]);
  const [optEtapes, setOptEtapes] = useState([]);

  const [alumnesIds, setAlumnesIds] = useState([]);
  const [usuarisIds, setUsuarisIds] = useState([]);
  const [classesIds, setClassesIds] = useState([]);
  const [cursosIds, setCursosIds] = useState([]);
  const [ciclesIds, setCiclesIds] = useState([]);
  const [etapesIds, setEtapesIds] = useState([]);

  useEffect(() => {
    buscarDades();
  },[refrescar]);


  useEffect(() => {
    //buscarDadesAlumnes();
    buscarDadesUsuaris();
    buscarDadesClasses();
    buscarDadesCursos();
    buscarDadesCicles();
    buscarDadesEtapes();
  },[]);


  const buscarDades = async () => {
    setTaulaIsLoading(true);
    const carpetes = await Api.get(`/documents/carpetes/${carpeta_personal}`);
    if(carpetes.result.success===1){
      const carpetes_t = carpetes.data.carpetes.map((item)=>{
        item.titol_t = entities.decode(item.titol);
        item.subtitol_t = entities.decode(item.subtitol);
        item.created_by_t = entities.decode(item.created_by);
        return item;
      });
      setLlista(carpetes_t);
    }
    setTaulaIsLoading(false);
  }

  const buscarDadesUsuaris = async () => {
    if(carpeta_personal==1){
       buscarDadesPares();
    }else{
       buscarDadesProfes();
    }
  }

  const buscarDadesPares = async () => {
    const c = await Api.get('/pares');
    if(c.result.success===1){
      const ids = [];
      const llista = c.data.pares.map((item) =>{
          if(item.users_id>0){
            ids.push(item.users_id);
            return(
              <Select.Option key={item.users_id} value={item.users_id}>
                {`${entities.decode(item.nom)} ${entities.decode(item.cognom1)} ${entities.decode(item.cognom2)}`}
              </Select.Option>
            );
          }
      });
      setUsuarisIds(ids);
      setOptUsuaris(llista);
    }
  }
  const buscarDadesProfes = async () => {
    const c = await Api.get('/professors');
    if(c.result.success===1){
      const ids = [];
      const llista = c.data.professors.map((item) =>{
          //if(item.users_id>0 && item.app_locked == 0){
          if(item.users_id>0){
            ids.push(item.users_id);
            return(
              <Select.Option key={item.users_id} value={item.users_id}>
                {item.app_locked ? <LockFilled style={{color: 'red'}}/> : ''}
                {`${entities.decode(item.nom)} ${entities.decode(item.cognom1)} ${entities.decode(item.cognom2)}`}
              </Select.Option>
            );
          }
      });
      setUsuarisIds(ids);
      setOptUsuaris(llista);
    }
  }
  const buscarDadesClasses = async () => {
    const c = await Api.get('/classes');
    if(c.result.success===1){
      const ids = [];
      const llista = c.data.classes.map((item) =>{
            ids.push(item.id);
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setClassesIds(ids);
      setOptClasses(llista);
    }
  }

  const buscarDadesCursos = async () => {
    const c = await Api.get('/cursos');
    if(c.result.success===1){
      const ids = [];
      const llista = c.data.cursos.map((item) =>{
            ids.push(item.id);
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setCursosIds(ids);
      setOptCursos(llista);
    }
  }
  const buscarDadesCicles = async () => {
    const c = await Api.get('/cicles');
    if(c.result.success===1){
      const ids = [];
      const llista = c.data.cicles.map((item) =>{
            ids.push(item.id);
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setCiclesIds(ids);
      setOptCicles(llista);
    }
  }
  const buscarDadesEtapes = async () => {
    const c = await Api.get('/etapes');
    if(c.result.success===1){
      const ids = [];
      const llista = c.data.etapes.map((item) =>{
            ids.push(item.id);
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setEtapesIds(ids);
      setOptEtapes(llista);
    }
  }




  const showAddFolder = () =>{

    let carpeta = InitDades.carpeta();
    carpeta.carpeta_personal = carpeta_personal == 1 ? true : false;
    setCarpetaActual(carpeta);
    setModalAfegirCarpetaVisible(true);
  }
  const showEditFolder = (value) =>{
    setCarpetaActual(value);
    console.log(value)
    setModalAfegirCarpetaVisible(true);
  }
  const submitDeleteFolder = async(id_carpeta) =>{
    const del = await Api.myFetch(`/documents/carpeta/${id_carpeta}`,'DELETE');
    if(del.result.success===1){
      const llista_carpetes = llista.filter((element, index, arr) => element.id !== id_carpeta);
      setLlista(llista_carpetes);

    }

  }

  const showAddFile = (carpeta) =>{
    setCarpetaActual(carpeta);
    const fit = InitDades.fitxer();
    fit.id_carpeta = carpeta.id;
    setFitxerActual(fit);
    setModalAfegirVisible(true);
  }

  const showEditFile = (value) =>{
    setFitxerActual(value);
    setModalAfegirVisible(true);
  }
  const submitDeleteFile = async(record) =>{
    //setCarpetaActual(record.id_carpeta);
    setCarpetaIsLoading(true);
    const del = await Api.myFetch(`/documents/fitxer/${record.id_fitxer}`,'DELETE');
    if(del.result.success===1){
      const llista_temp = llista.map((carpeta)=>{
        if(carpeta.id === record.id_carpeta){
          const llista_fitxers = carpeta.fitxers.filter((element, index, arr) => element.id_fitxer !== record.id_fitxer);
          carpeta.fitxers = llista_fitxers;
        }
        return carpeta;
      });
      setLlista(llista_temp)
    }
    setCarpetaIsLoading(false);
  }

  const submitSetFolderVisible = async(visible, id_carpeta) =>{
    const dades = {id_carpeta, visible}
    const vis = await Api.myFetch(`/documents/visible/carpeta/${id_carpeta}`, 'PUT', JSON.stringify(dades));
    if(vis.result.success===1){
      const llista_t = llista.map((item) =>{
        if(item.id === id_carpeta){
          item.visible = visible;
        }
        return item;
      });
      setLlista(llista_t);
    }
  }

  const submitSetFileVisible = async(visible, fitxer) =>{
    const dades = {id_fitxer: fitxer.id_fitxer, visible}
    const vis = await Api.myFetch(`/documents/visible/fitxer/${fitxer.id_fitxer}`, 'PUT', JSON.stringify(dades));
    if(vis.result.success===1){
      const llista_t = llista.map((carpeta) =>{
        if(carpeta.id === fitxer.id_carpeta){
          const fitxers = carpeta.fitxers.map((file) =>{
            if(file.id_fitxer ===fitxer.id_fitxer){
              file.visible = visible;
            }
            return file;
          });
          carpeta.fitxers = fitxers;
        }
        return carpeta;
      });
      setLlista(llista_t);
    }
  }


  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA


  const onExpandFiles = async (expanded, record) =>{
    if(expanded){
      setCarpetaActual(record)
      let expan = expandedRowKeys;
      expan.push(record.id);
      setExpandedRowKeys(expan);
    }else{
      const expan = expandedRowKeys.filter((element, index, arr) => element !== record.id);
      setExpandedRowKeys(expan);
    }
    if(record.num_fitxers===0) return;
    console.log('dins expand', record.id)
    await buscarFitxersDeCarpeta(record.id);

  }

  const buscarFitxersDeCarpeta = async(id_carpeta) =>{
    setCarpetaIsLoading(true);
    const fitxers = await Api.get(`/documents/fitxers/${id_carpeta}`);
    if(fitxers.result.success===1){
      const fitxers_t = fitxers.data.fitxers.map((item)=>{
        item.titol_t = entities.decode(item.titol);
        item.subtitol_t = entities.decode(item.subtitol);
        item.created_by_t = entities.decode(item.created_by);
        return item;
      });

      const llista_t = llista.map((item) =>{
        if(item.id === id_carpeta){
          item.fitxers = fitxers_t;
        }
        return item;
      });

      setLlista(llista_t);
    }
    setCarpetaIsLoading(false);
  }

  const respostaModalAfegir = () =>{
    buscarFitxersDeCarpeta(carpetaActual.id);
    setModalAfegirVisible(false);
  }


  const respostaModalAfegirCarpeta = () =>{
    setExpandedRowKeys([]);
    setRefrescar(refrescar+1);
    setModalAfegirCarpetaVisible(false);
  }


  const columnes_carpetes = [
    {
      title:"",
      key:"id",
      //sorter: (a, b) =>  a.id - b.id,
      render: (text,record) => (<FolderFilled style={{fontSize: '3rem', color: '#F7B334'}} />)
    },{
      title: props.t('documents.label_titol'),
      key:"titol_t",
      dataIndex: 'titol_t',
      sorter: (a, b) => { return a.titol_t.localeCompare(b.titol_t)},
      defaultSortOrder: 'ascend',
      ...getColumnSearchProps('titol_t',props.t('documents.label_titol'))
    },{
      title: props.t('documents.label_subtitol'),
      key:"subtitol_t",
      dataIndex: 'subtitol_t',
      sorter: (a, b) => { return a.subtitol_t.localeCompare(b.subtitol_t)},
      ...getColumnSearchProps('subtitol_t',props.t('documents.label_subtitol'))
    },{
        title:props.t('documents.num'),
        key:"num_fitxers",
        dataIndex:"num_fitxers",
        sorter: (a, b) =>  a.num_fitxers - b.num_fitxers,
    },{
      title:props.t('documents.num_nous'),
      key:"num_fitxers_nous",
      dataIndex:"num_fitxers_nous",
      sorter: (a, b) =>  a.num_fitxers_nous - b.num_fitxers_nous,
    },{
      title: props.t('generic.created_by'),
      key:"created_by_t",
      dataIndex: 'created_by_t',
      sorter: (a, b) => { return a.created_by_t.localeCompare(b.created_by_t)},
      ...getColumnSearchProps('created_by_t',props.t('generic.created_by'))
    },{
      title:props.t('generic.created_at'),
      key:"created_at",
      sorter: (a, b) => moment(a.created_at).diff(moment(b.created_at)),
      responsive:['md'],
      render:(text,record) => (record.created_at ? <Moment date={new Date(record.created_at)} format="DD/MM/YYYY"/> : "" )
    },{
      key: "actions",
      align: "right",
      width: "115px",
      render: (text,record) => {
        const ull = record.visible ? <EyeTwoTone twoToneColor='#81c784 '/> : <EyeInvisibleTwoTone twoToneColor='red'/>;

        return (
        <Row type="flex" justify="end" id={"actions_"+record.id}>

          <Col span={8}>
            <Tooltip placement="top" title={props.t('generic.visible')}>
              <Popconfirm placement="bottom" title={props.t('generic.visible_pregunta')} okText={props.t('generic.si')} cancelText={props.t('generic.no')} onConfirm={()=>submitSetFolderVisible(true, record.id)} onCancel={()=>submitSetFolderVisible(false, record.id)}>
                <Button icon={ull} ghost="true" className="BotoTaula"/>
              </Popconfirm>
            </Tooltip>
          </Col>
          <Col span={8}>
            <Tooltip placement="top" title={props.t('generic.modify')}>
              <Button icon={<EditOutlined />} onClick={(e) => showEditFolder(record)} ghost="true" className="BotoTaula"/>
            </Tooltip>
          </Col>
          <Col span={8}>
            {record.num_fitxers > 0 ? null :
              <Tooltip placement="top" title={props.t('generic.delete')}>
                <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText={props.t('generic.si')} cancelText={props.t('generic.no')} onConfirm={()=>submitDeleteFolder(record.id)}>
                  <Button icon={<DeleteOutlined />} ghost="true" className="BotoTaula"/>
                </Popconfirm>
              </Tooltip>
            }
          </Col>
        </Row>
      )
      }
    }
  ];

  const columnes_fitxers = [
    {
      title:"",
      key:"id_fitxer",
      render: (text,record) => {

        let icona_fitxer = null;
        switch (record.ext.toLowerCase()) {
          case 'pdf':
            icona_fitxer = <FilePdfTwoTone twoToneColor='red' style={{fontSize: '3rem'}}/>;
            break;
          case 'csv':
          case 'xls':
          case 'xlsx':
            icona_fitxer = <FileExcelTwoTone twoToneColor='#4caf50' style={{fontSize: '3rem'}}/>;
            break;
          case 'doc':
          case 'docx':
          case 'odt':
            icona_fitxer = <FileWordTwoTone twoToneColor='#03a9f4' style={{fontSize: '3rem'}}/>;
            break;
          case 'ppt':
          case 'pptx':
            icona_fitxer = <FilePptTwoTone twoToneColor='#ff7043' style={{fontSize: '3rem'}}/>;
            break;
          case 'txt':
            icona_fitxer = <FileTextTwoTone twoToneColor='#bdbdbd' style={{fontSize: '3rem'}}/>;
            break;
          default:
            icona_fitxer = <FileImageTwoTone twoToneColor='#bdbdbd' style={{fontSize: '3rem'}} />;
        }


        if(record.nou){
          return (
            <Badge count={<span style={{color: '#52c41a', fontWeight :'bold'}}>NEW</span>} offset={[0,0]}>
              {icona_fitxer}
            </Badge>
          )
        }else{
          return icona_fitxer;
        }

      }
    },{
      title: props.t('documents.label_titol'),
      key:"titol_t",
      dataIndex: 'titol_t',
      sorter: (a, b) => { return a.titol_t.localeCompare(b.titol_t)},
      ...getColumnSearchProps('titol_t',props.t('documents.label_titol'))
    },{
      title: props.t('documents.label_subtitol'),
      key:"subtitol_t",
      dataIndex: 'subtitol_t',
      sorter: (a, b) => { return a.subtitol_t.localeCompare(b.subtitol_t)},
      ...getColumnSearchProps('subtitol_t',props.t('documents.label_subtitol'))
    },{
      title: props.t('documents.nom_fitxer'),
      key:"nom_original",
      dataIndex: 'nom_original',
      sorter: (a, b) => { return a.nom_original.localeCompare(b.nom_original)},
      ...getColumnSearchProps('nom_original',props.t('documents.nom_fitxer'))
    },{
      title: props.t('generic.created_by'),
      key:"created_by_t",
      dataIndex: 'created_by_t',
      sorter: (a, b) => { return a.created_by_t.localeCompare(b.created_by_t)},
      ...getColumnSearchProps('created_by_t',props.t('generic.created_by'))
    },{
      title:props.t('generic.updated_at'),
      key:"updated_at",
      dataIndex:"updated_at",
      sorter: (a, b) => { return moment(a.updated_at).diff(moment(b.updated_at))},
      defaultSortOrder: 'descend',
      //responsive:['md'], //Si el poso no em deixa ordenar per defecte
      render:(text,record) => (record.updated_at ? <Moment date={new Date(record.updated_at)} format="DD/MM/YYYY"/> : "" )
    },{
      key: "actions",
      align: "right",
      width: "125px",
      render: (text,record) => {
        console.log(record)
        const ull = record.visible ? <EyeTwoTone twoToneColor='#81c784 '/> : <EyeInvisibleTwoTone twoToneColor='red'/>;
        return (
          <Row type="flex" justify="end" id={"actions_"+record.id}>
            <Col span={6}>
              <Tooltip placement="top" title={props.t('generic.descarregar')}>
                  <Button icon={<DownloadOutlined />} ghost="true" className="BotoTaula" download={record.nom_original} onClick={() => window.open(record.link)}/>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip placement="top" title={props.t('generic.visible')}>
                <Popconfirm placement="bottom" title={props.t('generic.visible_pregunta')} okText={props.t('generic.si')} cancelText={props.t('generic.no')} onConfirm={()=>submitSetFileVisible(true, record)} onCancel={()=>submitSetFileVisible(false, record)}>
                  <Button icon={ull} ghost="true" className="BotoTaula"/>
                </Popconfirm>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip placement="top" title={props.t('generic.modify')}>
                <Button icon={<EditOutlined />} onClick={(e) => showEditFile(record)} ghost="true" className="BotoTaula"/>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip placement="top" title={props.t('generic.delete')}>
                <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText={props.t('generic.si')} cancelText={props.t('generic.no')} onConfirm={()=>submitDeleteFile(record)}>
                  <Button icon={<DeleteOutlined />} ghost="true" className="BotoTaula"/>
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
    );}
    }
  ];
  const subTaulaFitxers = row => {
    return (
      <div>
      <Row justify="end">
      <Col>
        <Button type="primary" style={{zIndex: 999}} onClick={() => showAddFile(row)}>{props.t('generic.add')} {props.t('documents.fitxer')}</Button>
      </Col>
      </Row>
      <Row style={{margin: '1rem'}}>
        <Col span={22}>
          <Table
            loading={carpetaIsLoading && carpetaActual.id == row.id}
            rowKey="id_fitxer"
            columns={columnes_fitxers}
            dataSource={row.fitxers}
            pagination={false}
            size={'small'}
            bordered={false}/>
          </Col>
      </Row>
      </div>
    );
  };

  return(
    <>

    <div style={{textAlign:'right', marginBottom:'25px'}}>
      <Row type="flex" justify="space-between">
        <Col>
          <div>
            <FolderOutlined style={{color:'#C2C2C2'}}/>
            <span style={{color:'#C2C2C2'}}>&nbsp;{typeof llista !== "undefined" ? llista.length : 0}&nbsp;{props.t('documents.creados')}</span>
          </div>
        </Col>
        <Col>
          <Button type="primary" onClick={()=>showAddFolder()}>{props.t('documents.crear_carpeta')}</Button>
        </Col>
      </Row>
    </div>


        <Table
          loading={taulaIsLoading}
          rowKey="id"
          txt_creats={props.t('documents.creados')}
          txt_nou={props.t('documents.crear_carpeta')}
          buscarDades={buscarDades}
          dataSource={llista}
          columns={columnes_carpetes}
          expandedRowRender={subTaulaFitxers}
          defaultExpandAllRows={false}
          expandedRowKeys={expandedRowKeys}
          //expandRowByClick={true}
          onExpand={(expanded, record) => onExpandFiles(expanded, record)}
          //rowExpandable={(record) => record.num_fitxers > 0}
          pagination={false}
          bordered={false}
          />

          <Modal
            key="afegir_fitxer"
            visible={modalAfegirVisible}
            footer={null}
            width={450}
            style={{top:10}}
            bodyStyle={{ overflow: "auto"}}
            destroyOnClose={true}
            forceRender={true}
            maskClosable={true}
            closable={true}
            onCancel={() => setModalAfegirVisible(false)}
          >
            <AfegirDocument fitxer={fitxerActual} callback={respostaModalAfegir}/>
          </Modal>
          <Modal
            key="afegir_carpeta"
            visible={modalAfegirCarpetaVisible}
            footer={null}
            width={850}
            style={{top:10}}
            bodyStyle={{ overflow: "auto"}}
            destroyOnClose={true}
            forceRender={true}
            maskClosable={true}
            closable={true}
            onCancel={() => setModalAfegirCarpetaVisible(false)}
          >
            <AfegirCarpeta
              carpeta={carpetaActual}
              callback={respostaModalAfegirCarpeta}
              usuarisIds={usuarisIds}
              optUsuaris={optUsuaris}
              classesIds={classesIds}
              optClasses={optClasses}
              cursosIds={cursosIds}
              optCursos={optCursos}
              ciclesIds={ciclesIds}
              optCicles={optCicles}
              etapesIds={etapesIds}
              optEtapes={optEtapes}
              />
          </Modal>
    </>
  );

}

function Documents(props) {

  const [tabActual, setTabActual] = useState(0);

  const tabChange = (key) =>{
    setTabActual(key);
  }

  return (
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.Documents')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <Tabs defaultActiveKey={tabActual} onChange={tabChange}>
          <Tabs.TabPane tab={props.t('documents.carpetes_escola')} key="0">
            <Carpetes carpeta_personal={0} t={props.t}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab={props.t('documents.carpetes_personals')} key="1">
            <Carpetes carpeta_personal={1} t={props.t}/>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </ContainerPagina>
  );
}



export default translate(Documents);
